import React from 'react';
import autobind from 'autobind-decorator';

import Layout from '../components/layout';
import SEO from '../components/seo';

import Gallery from 'react-photo-gallery';
import Lightbox from 'react-images';
import GalleryImage from '../components/GalleryImage';

import { graphql } from 'gatsby';

const getImages = (data: any) => {
  const result = [];
  for (const imgKey of Object.keys(data)) {
    const img = data[imgKey].childImageSharp.fluid as any;
    result.push({
      src: img.src,
      srcSet: img.srcSet,
      sizes: img.sizes,
      width: img.presentationWidth,
      height: img.presentationHeight,
      alt: '',
      fluid: data[imgKey].childImageSharp.fluid
    });
  }

  return result;
};

class DesignsPage extends React.Component<DesignsPageProps, DesignsPageState> {
  hammer: HammerManager | undefined;
  constructor(props: DesignsPageProps) {
    super(props);

    this.state = {
      images: getImages(props.data),
      currentImage: 0,
      lightboxIsOpen: false,
    };
  }

  @autobind
  openLightbox(event: React.MouseEvent, obj: any) {
    this.setState(
      {
        currentImage: obj.index,
        lightboxIsOpen: true,
      },
      () => {
        setTimeout(async () => {
          const lb = document.querySelector('#lightboxBackdrop') as HTMLElement;
          if (!lb) {
            return;
          }
          const Hammer = await import('hammerjs');
          this.hammer = new Hammer.default(lb);
          this.hammer.get('swipe').set({ direction: Hammer.DIRECTION_HORIZONTAL });
          this.hammer.on('swipe', (ev) => {
            if (ev.type === 'swipe' && ev.offsetDirection === Hammer.DIRECTION_LEFT) {
              this.gotoNext();
            } else if (ev.type === 'swipe' && ev.offsetDirection === Hammer.DIRECTION_RIGHT) {
              this.gotoPrevious();
            } else if (ev.type === 'tap') {
              this.closeLightbox();
            }
          });
        });
      }
    );
  }

  @autobind
  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });

    if (this.hammer) {
      this.hammer.destroy();
      this.hammer = undefined;
    }
  }

  @autobind
  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage > 0 ? this.state.currentImage - 1 : 0,
    });
  }

  @autobind
  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage < this.state.images.length - 1 ? this.state.currentImage + 1 : this.state.images.length - 1,
    });
  }

  render() {
    const { images } = this.state;

    return (
      <Layout pageTitle="Designs">
        <SEO title="Designs" />
        <div className="designs">
          <Gallery photos={images} margin={2} width={700} columns={6} onClick={this.openLightbox} ImageComponent={GalleryImage} />
          <Lightbox
            images={images}
            theme={{
              footerCount: {
                color: 'black'
              },
              image: {
                maxHeight: '75vh'
              }
            }}
            onClose={this.closeLightbox}
            onClickPrev={this.gotoPrevious}
            onClickNext={this.gotoNext}
            currentImage={this.state.currentImage}
            isOpen={this.state.lightboxIsOpen}
          />
        </div>
      </Layout>
    );
  }
}

interface DesignsPageProps {
  data: any;
}

interface DesignsPageState {
  images: any[];
  currentImage: number;
  lightboxIsOpen: boolean;
}

export default DesignsPage;

export const fluidImage = graphql`
fragment fluidImage on File {
  childImageSharp {
    fluid(maxWidth: 1000) {
      ...GatsbyImageSharpFluid_tracedSVG
      presentationWidth
      presentationHeight
    }
  }
}
`;

export const pageQuery = graphql`
query {
  image03_01: file(relativePath: { eq: "designs/Asset 3 - 1.jpg" }) {
    ...fluidImage
  },
  image03_02: file(relativePath: { eq: "designs/Asset 3 - 2.png" }) {
    ...fluidImage
  },
  image03_03: file(relativePath: { eq: "designs/Asset 3 - 3.png" }) {
    ...fluidImage
  },
  image03_04: file(relativePath: { eq: "designs/Asset 3 - 4.png" }) {
    ...fluidImage
  },
  image03_05: file(relativePath: { eq: "designs/Asset 3 - 5.png" }) {
    ...fluidImage
  },
  image03_06: file(relativePath: { eq: "designs/Asset 3 - 6.jpg" }) {
    ...fluidImage
  },
  image03_07: file(relativePath: { eq: "designs/Asset 3 - 7.png" }) {
    ...fluidImage
  },
  image03_08: file(relativePath: { eq: "designs/Asset 3 - 8.png" }) {
    ...fluidImage
  },
  image03_09: file(relativePath: { eq: "designs/Asset 3 - 9.png" }) {
    ...fluidImage
  },
  image02_01: file(relativePath: { eq: "designs/Asset 2 - 1.png" }) {
    ...fluidImage
  },
  image02_02: file(relativePath: { eq: "designs/Asset 2 - 2.png" }) {
    ...fluidImage
  },
  image02_03: file(relativePath: { eq: "designs/Asset 2 - 3.png" }) {
    ...fluidImage
  },
  image02_04: file(relativePath: { eq: "designs/Asset 2 - 4.png" }) {
    ...fluidImage
  },
  image02_05: file(relativePath: { eq: "designs/Asset 2 - 5.png" }) {
    ...fluidImage
  },
  image02_06: file(relativePath: { eq: "designs/Asset 2 - 6.png" }) {
    ...fluidImage
  },
  image02_07: file(relativePath: { eq: "designs/Asset 2 - 7.png" }) {
    ...fluidImage
  },
  image02_08: file(relativePath: { eq: "designs/Asset 2 - 8.png" }) {
    ...fluidImage
  },
  image02_09: file(relativePath: { eq: "designs/Asset 2 - 9.png" }) {
    ...fluidImage
  },
  image02_10: file(relativePath: { eq: "designs/Asset 2 - 10.jpg" }) {
    ...fluidImage
  },
  image01: file(relativePath: { eq: "designs/Asset 1.png" }) {
    ...fluidImage
  },
  image02: file(relativePath: { eq: "designs/Asset 2.png" }) {
    ...fluidImage
  },
  image03: file(relativePath: { eq: "designs/Asset 3.png" }) {
    ...fluidImage
  },
  image04: file(relativePath: { eq: "designs/Asset 4.png" }) {
    ...fluidImage
  },
  image05: file(relativePath: { eq: "designs/Asset 5.png" }) {
    ...fluidImage
  },
  image06: file(relativePath: { eq: "designs/Asset 6.png" }) {
    ...fluidImage
  },
  image07: file(relativePath: { eq: "designs/Asset 7.png" }) {
    ...fluidImage
  },
  image08: file(relativePath: { eq: "designs/Asset 8.png" }) {
    ...fluidImage
  },
  image09: file(relativePath: { eq: "designs/Asset 9.png" }) {
    ...fluidImage
  },
  image10: file(relativePath: { eq: "designs/Asset 10.png" }) {
    ...fluidImage
  },
  image11: file(relativePath: { eq: "designs/Asset 11.png" }) {
    ...fluidImage
  },
  image12: file(relativePath: { eq: "designs/Asset 12.png" }) {
    ...fluidImage
  },
  image13: file(relativePath: { eq: "designs/Asset 13.png" }) {
    ...fluidImage
  },
  image14: file(relativePath: { eq: "designs/Asset 14.png" }) {
    ...fluidImage
  },
  image15: file(relativePath: { eq: "designs/Asset 15.png" }) {
    ...fluidImage
  },
  image16: file(relativePath: { eq: "designs/Asset 16.png" }) {
    ...fluidImage
  },
  image17: file(relativePath: { eq: "designs/Asset 17.png" }) {
    ...fluidImage
  },
  image18: file(relativePath: { eq: "designs/Asset 18.png" }) {
    ...fluidImage
  },
  image19: file(relativePath: { eq: "designs/Asset 19.png" }) {
    ...fluidImage
  },
  image20: file(relativePath: { eq: "designs/Asset 20.png" }) {
    ...fluidImage
  },
  image21: file(relativePath: { eq: "designs/Asset 21.png" }) {
    ...fluidImage
  },
  image22: file(relativePath: { eq: "designs/Asset 22.png" }) {
    ...fluidImage
  },
  image23: file(relativePath: { eq: "designs/Asset 23.png" }) {
    ...fluidImage
  },
}
`;